import React, { createContext, useReducer, useState } from "react";

export const OrderContext = createContext();

const collection = new Map();

const setHashElement = (key, value) => {
  collection.set(key, value);
};

const initialStatus = {
  status: "",
};

const statusReducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { ...state, status: action.value };

    default:
      return state;
  }
};

export const OrderProvider = ({ children }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isShiftDown, setShiftDown] = useState(false);
  const [lastSelectedItem, setLastSelectedItem] = useState(null);

  const [checkShippingFeild, setCheckShippingFeild] = useState(true);

  const [city, setCity] = useState("");

  const [addedItemInOrder, setAddedItemInOrder] = useState([]);

  const [removeItemInOrder, setRemoveItemInOrder] = useState([]);
  const [chekcedOrderId, setChekcedOrderId] = useState([]);

  const [orderNote, setOrderNote] = useState("");

  const [marchentNote, setMarchentNote] = useState("");

  const changeMarchentNote = (value) => {
    setMarchentNote(value);
  };

  const incrementCheckedOrderId = (id) => {
    setChekcedOrderId((prev) => [...prev, id]);
  };
  const pullIdFromCheckOrder = (id) => {
    setChekcedOrderId(chekcedOrderId.filter((item) => item !== id));
  };
  const emptyCheckedOrderList = () => {
    setChekcedOrderId([]);
  };

  const updateOrderNote = (info) => {
    setOrderNote(info);
  };

  const addItemInOrderHandler = (product) => {
    setAddedItemInOrder((prev) => [...prev, product]);
  };

  const removeItemInOrderHandler = (product) => {
    setRemoveItemInOrder((prev) => [...prev, product]);
  };

  const setItemInOrderInitial = () => {
    setAddedItemInOrder([]);
    setRemoveItemInOrder([]);
  };

  const shippingFeildToFalse = () => {
    setCheckShippingFeild(false);
  };

  const shippingFeildToTrue = () => {
    setCheckShippingFeild(true);
  };

  const [multiStatus, statusDispatch] = useReducer(
    statusReducer,
    initialStatus
  );

  const value = {
    multiStatus,
    statusDispatch,
    checkShippingFeild,
    shippingFeildToFalse,
    shippingFeildToTrue,
    city,
    setCity,
    collection,
    setHashElement,
    addedItemInOrder,
    addItemInOrderHandler,
    removeItemInOrder,
    removeItemInOrderHandler,
    setItemInOrderInitial,
    chekcedOrderId,
    incrementCheckedOrderId,
    pullIdFromCheckOrder,
    emptyCheckedOrderList,
    marchentNote,
    changeMarchentNote,

    selectedItems,
    isShiftDown,
    lastSelectedItem,
    setSelectedItems,
    setShiftDown,
    setLastSelectedItem,
    orderNote,
    updateOrderNote,
  };

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};
