import React, { createContext, useReducer, useRef, useState } from "react";

export const FilterContext = createContext();

const initialStateCart = [];

function reducerForCart(state, action) {
  switch (action.type) {
    case "add":
      return [...state, action.value];
    case "addall":
      return action.value;
    case "null":
      return [];
    case "remove":
      return action.value;

    default:
      return state;
  }
}

const initialTotalForOrder = 0;

function reducerTotalForOrder(state, action) {
  switch (action.type) {
    case "add":
      return parseInt(state) + parseInt(action.value);
    case "sub":
      return parseInt(state) - parseInt(action.value);
    case "addAndSub":
      return parseInt(state) + parseInt(action.value);
    case "initial":
      return parseInt(action.value);

    default:
      return state;
  }
}

//sub total COD calculation
const initialSubTotalCOD = 0;
function reducerForSubTotalCOD(state, action) {
  switch (action.type) {
    case "add":
      return state + action.value;
    case "sub":
      return state - action.value;
    case "initial":
      return action.value;

    default:
      return state;
  }
}

const initialShippingCost = 0;

function reducerInitialShippingCost(state, action) {
  switch (action.type) {
    case "add":
      return state * 10 + action.value;
    case "sub":
      return state - action.value;

    case "initial":
      return action.value;

    default:
      return state;
  }
}

export const FilterProvider = ({ children }) => {
  const inputRef = useRef([]);
  const [total, dispatchTotal] = useReducer(
    reducerTotalForOrder,
    initialTotalForOrder
  );
  const [shippingCost, dispatchShippingCost] = useReducer(
    reducerInitialShippingCost,
    initialShippingCost
  );

  const [cart, dispatchCart] = useReducer(reducerForCart, initialStateCart);
  const [subTotalCOD, subTotalCODDispatch] = useReducer(
    reducerForSubTotalCOD,
    initialSubTotalCOD
  );
  const [due, setDue] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [paid, setPaid] = useState(0);

  const value = {
    cart,
    dispatchCart,
    total,
    dispatchTotal,
    shippingCost,
    dispatchShippingCost,
    inputRef,
    subTotalCOD,
    subTotalCODDispatch,
    due,
    setDue,
    discount,
    setDiscount,
    paid,
    setPaid,
  };

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};
